//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-376:_1232,_5336,_7668,_5070,_4304,_6688,_3888,_8160;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-376')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-376', "_1232,_5336,_7668,_5070,_4304,_6688,_3888,_8160");
        }
      }catch (ex) {
        console.error(ex);
      }